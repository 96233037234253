<template>
  <footer class="footer">
      <div class="footer__container">
          <div class="footer__logo">
              <img src="@/assets/logo/pad-sotrad.png" alt="logo pump and drink by sotrad water" class="footer__logo--img">
          </div>
          <div class="footer__social">
              <ul>
                  <li v-for="soc in social">
                      <a :href="soc.link" target="_blank" :aria-label="soc.aria" rel="noopener noreferrer">
                          <Svg :icon="soc.svg" :className="`footer__social--svg ${soc.svg == 'youtube' ? 'footer__social--svg-youtube' : ''}`"/>
                      </a>
                  </li>
              </ul>
          </div>
          <div class="footer__content">
              <div class="footer__col">
                  <h2>
                      {{t('headers.nav')}}
                  </h2>
                  <ul>
                      <li v-for="item in menu" :key="item.link">
                          <router-link :to="{name:item.link}" class="footer__col--nav">
                              {{item.text}}
                          </router-link>
                      </li>
                  </ul>
              </div>
              <div class="footer__col">
                   <h2>
                       {{t('headers.contact')}}
                   </h2>
                   <ul>
                       <li>
                            <a href="tel:003267214464" class="footer__col--line">
                                <Svg icon="phone" className="footer__col--svg"/>
                                +32 67 21 44 64
                            </a>
                       </li>
                       <li>
                           <a href="mailto:info@pumpanddrink.com" class="footer__col--line">
                                <Svg icon="email" className="footer__col--svg"/>
                                info@pumpanddrink.com
                           </a>
                       </li>
                   </ul>
              </div>
              <div class="footer__col">
                   <h2>
                       {{t('headers.office')}}
                   </h2>
                   <ul>
                       <li class="footer__col--line">
                           <Svg icon="office" className="footer__col--svg"/>
                           <div>
                                rue de l'artisanat 5a <br>
                                B-1400 Nivelles <br>
                                Belgium
                           </div>
                            
                       </li>
                   </ul>
              </div>
          </div>
          <div class="footer__bottom">
              <div class="footer__bottom--item">
                  	&#169; Pump&Drink 2021
              </div>
              <div class="footer__bottom--item">
                  {{t('power')}}
                  <a href="https://www.sotradwater.be" class="footer__bottom--link">
                    <img src="@/assets/logo/sotrad-water-small.png" alt="logo sotrad water">
                  </a>
              </div>
          </div>
      </div>
  </footer>
</template>

<script>
 import Svg from '../components/utils/Svg';
 import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export default {
    components: {Svg},
    setup(){
        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/footer.json'),
              fr: require('@/locales/fr/footer.json'),
              pt: require('@/locales/pt/footer.json'),
              sw: require('@/locales/sw/footer.json')
            }
        })
        const social = [
            {
                aria: "link youtube channel sotrad water",
                link: "https://www.youtube.com/channel/UCDdGFOHejC0s9pApaxgD5pQ",
                svg: "youtube"
            },
            {
                aria: "link linkedin profile pump&drink",
                link: "https://www.linkedin.com/showcase/pump-drink",
                svg: "linkedin"
            },
            {
                aria: "instagram page pump and drink",
                link: "https://www.instagram.com/pump_and_drink/",
                svg: "instagram"
            }
        ]
        const menu = computed(() => [
            {
                link: "Home",
                text: t('nav.home')
            },
             {
                link: "Pump",
                text: t('nav.pump')
            },
             {
                link: "Project",
                text: t('nav.project')
            },
             {
                link: "Team",
                text: t('nav.team')
            },
        ])
        return {menu, social, t}
    }

}
</script>

<style lang='scss'>

@import '../style/main';

.footer{
    background-color: $color-white;
    border-top: .3rem solid $color-primary;
    box-shadow: 0rem -0.7rem 1rem rgba($color-black, 0.2); /*x y blur color*/

    &__container{
        padding: 4rem 0;
        max-width: $page-max-width;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(phone){
            margin: 0 $margin-section-phone;
        }
    }

    &__logo{
        width: 15rem;
        &--img{
            width: 100%;
            height: auto;
        }
    }

    &__social{
        padding: 2rem 0;
        & ul{
            display: flex;
        }

        & li{
            list-style: none;
            &:not(:last-child){
                margin-right: 1.4rem;
            }
            transition: all .2s;
            &:hover{
                transform: scale(1.1);
            }

        }

        & a {
            color:inherit;
            text-decoration: none;
            height: 4rem
            
        }

        &--svg{
            @include svg(4rem);
            &-youtube{
                width: 6rem;
            }
        }
    }

    &__content{
        padding: 2.4rem 0;
        width: 100%;
        display: flex;
        justify-content: space-between;

        @include respond(phone){
            flex-direction: column;
            align-items: center;
        }
        
    }

    &__col{

        & h2{
            margin-bottom: 1rem;
            text-transform: uppercase;
            letter-spacing: .1rem;
            font-size: 1.8rem;
        }

        & ul{
            font-size: 1.6rem;
        }

        & li{
            list-style: none;
            text-transform: uppercase;
            &:not(:last-child){
                margin-bottom: .4rem;
            }

            @include respond(phone){
                text-align: center;
            }
        }

        & a{
            
            text-decoration: none;
            color:inherit
        }

        @include respond(phone){
            margin-bottom: 3rem;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &--line{
            display: flex;
            align-items: center;
            margin-bottom: 1.4rem;

            @include respond(phone){
                justify-content: center
            }
        }

        &--svg{
            @include svg(2.4rem);
            margin-right: 1.6rem;
        }

        &--nav{
            @include hoverUnderline;
        }

    }



    &__bottom{
        width: 100%;
        padding: 1.2rem 0;
        border-top: 2px solid $color-primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;
        text-transform: uppercase;
        letter-spacing: .1rem;


        &--item{
            display: flex;
            align-items: center;

            @include respond(small){
                margin-bottom: 1rem;
            }
        }

        &--link{
            & img{
                height: 2.4rem;
                width: auto;
                margin-left: 1rem;
            }
        }

        @include respond(small){
            flex-direction: column-reverse;
        }
    }
}

</style>