<template>
    <div class="title">
        <h2>
            <slot/>
        </h2>
        <div v-if="logo && link" class="u-hide-phone">
            <a :href="link" target="_blank" rel="noopener noreferrer" :aria-label="`link to ${link}`">
                <img :src="require(`@/assets/logo/${logo}`)" alt="">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props:['logo', 'link']
}
</script>

<style lang="scss">
@import '@/style/main';

.title{
    border-bottom: 2px solid currentColor;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(phone){
        flex-direction: column;
        align-items: flex-start;
    }

    & h2{
        font-size: 3rem;
        text-transform: uppercase;
        letter-spacing: .2rem;
        margin-bottom: .5rem;
        font-weight: 600;

        @include respond(phone){
            font-size: 2.1rem;
            letter-spacing: 0rem;
        }
    }

    & img{
        height: 4rem;

    }
}

</style>