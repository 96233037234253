<template>
    <section-title>{{t('title')}}</section-title>
    <div class="deploy">
        <div v-for="i in content" :key="i.icon" class="deploy__item">
            <img :src="require(`@/assets/icons/${i.icon}.svg`)" :alt="i.alt" class="deploy__item--img"/>
            <h3>
                {{i.title}}
            </h3>
            <h4>
                {{i.content}}
            </h4>
        </div>
    </div>
</template>

<script>


import SectionTitle from '@/components/section/SectionTitle';
 import { useI18n } from 'vue-i18n';
import Svg from '../utils/Svg.vue';
import { computed } from 'vue';
export default {
    components: {
        SectionTitle,
        Svg
    },
    setup(){
         const {t} = useI18n({
            messages:{
              en: require('@/locales/en/deployment.json'),
              fr: require('@/locales/fr/deployment.json'),
              pt: require('@/locales/pt/deployment.json'),
              sw: require('@/locales/sw/deployment.json')
            }
        })
        const content= computed(() => [
            {
                icon:"solar",
                title:t('features[0].quantity') + " MW",
                alt:t('features[0].alt'),
                content:t('features[0].content')
            },
            {
                icon:"people",
                title:t('features[1].quantity'),
                alt:t('features[1].alt'),
                content:t('features[1].content')
            },
            {
                icon:"pumpanddrink",
                title:t('features[2].quantity'),
                alt:t('features[2].alt'),
                content:t('features[2].content')
            },
            {
                icon:"water",
                title:t('features[3].quantity') + " m³",
                alt:t('features[3].alt'),
                content:t('features[3].content')
            }
        ])

        return {content, t}
    }

}
</script>

<style lang='scss' scoped>
@import '../../style/main';

.deploy{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    @include respond(phone){
        flex-direction: column;
    }
    &__item{
        background-color: $color-white;
        box-shadow: $shadow;
        padding: 3rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 0 0 23%;

        text-align: center;
        @include respond(phone){
            margin-bottom: 3rem;
        }
        &--img{
            height: 10rem;
            margin-bottom: 2rem;

            @include respond(phone){
                height: 7rem;
            }
        }

        & h3{
            font-size: 3.6rem;
            margin-bottom: 1rem;
            font-weight: 700;
             @include respond(phone){
                font-size: 2.7rem;
            }
        }

        & h4{
            font-weight: 400;
        }
    }
}
</style>