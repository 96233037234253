<template>
    <div class="action">
        <div class="action__image">
            <img :src="require(`@/assets/gallery/${data.img}`)" alt="african girl standing nearby source of running water" class="action__image--img">
        </div>
        <div class="action__content">
            <div v-if="data.quote">
                <Svg icon="quote" className="action__content--svg"></Svg>
            </div>
            <h2>
                <slot></slot>
            </h2>
            <h3 v-if="data.quoteFrom">
                {{data.quoteFrom}}
            </h3>
            <button-cta :data="data.cta"></button-cta>
        </div>
        
    </div>
</template>

<script>
import ButtonCta from '../utils/ButtonCta';
import Svg from '@/components/utils/Svg';
export default {
    components:{
        ButtonCta,
        Svg
    },
    props:['data'],
}
</script>

<style lang='scss' scoped>
    @import '@/style/main';
    .action{
        display: flex;

        @include respond(phone){
            flex-direction: column;
        }

        &__image{
            flex: 0 0 55%;
            align-self: stretch;
            margin-right: 4rem;

            @include respond(phone){
               margin-right: 0;
            }


            &--img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &__content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 4rem 0;

             @include respond(phone){
                padding-bottom: 0;
            }

            & h2{
                font-weight: 400;
                text-transform: uppercase;
                font-size: 3rem;
                letter-spacing: .2rem;
                line-height: 3.5rem;
            }

            & h3{
                font-size: 1.8rem;
                font-weight: 300;
                margin: 1rem 0;
            }

            &--svg{
                color:$color-secondary;
                @include svg(5rem);
                margin-bottom: 2rem;
            }
        }
    }

</style>