import { createStore } from "vuex";

export default createStore({
  state: {
    language: "en",
    languageName: "English",
    popup: {
      data: {},
      display: false,
      type: "video",
    },
  },
  mutations: {
    hidePopup(state) {
      state.popup.display = false;
    },
  },
  actions: {},
  modules: {},
});
