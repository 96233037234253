<template>
  <section-title>{{t('title')}}</section-title>
    <div class="testi__nav">
        <button id="prev-testimony" aria-label="previous testimony">
            <Svg icon="arrow-prev" className="testi__nav--svg"/>
        </button>
        <span>1</span>  -  <span>{{testimony.length}}</span>
        <button id="next-testimony" aria-label="next testimony">
            <Svg icon="arrow-next" className="testi__nav--svg"/>
        </button>
    </div>
  <div id="carousel-testimony" class="testi">
      <div v-for="t in testimony" :key="t.img" class="testi__item">
          <img v-if="t.img" :src="require(`@/assets/testimony/${t.img}`)" alt="">
          <h3>
              {{t.name}}
          </h3>
          <h4>
              {{t.role}}
          </h4>
          <div class="testi__content">
              <Svg icon="quote" className="testi__content--svg"/>
              <p>
                  {{t.text}}
              </p>
          </div>
      </div>
  </div>
</template>

<script>
import $ from 'jquery';
import slick from 'slick-carousel';

import {displaySize} from '@/composables/displaySize';

import SectionTitle from '@/components/section/SectionTitle';
import Svg from '../utils/Svg.vue';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    components: {
        SectionTitle,
        Svg
    },
    setup(){

        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/testimony.json'),
              fr: require('@/locales/fr/testimony.json'),
              pt: require('@/locales/pt/testimony.json'),
              sw: require('@/locales/sw/testimony.json')
            }
        })

        onMounted(() => {
            const screen = displaySize();
            let numberTestimony = 3;

            if(screen === 'phone'){
                numberTestimony = 1;
            }
             $("#carousel-testimony").slick({
                infinite: false,
                slidesToShow: numberTestimony,
                slidesToScroll: 1,
                rows:0,
                prevArrow: $("#prev-testimony"),
                nextArrow: $("#next-testimony"),
            });
        })

        
        const testimony= computed(() => [
            
            
            {
                img:"villageois.jpg",
                name: "Village de Lelouhouroukaha",
                role:t('content[0].role'),
                text:t('content[0].text')
            },
           
             {
                img:"yo-kassoum.jpg",
                name: "Yo Kassoum",
                role:t('content[2].role'),
                text:t('content[2].text')
            },
             {
                img:"serge.jpg",
                name: "Serge",
                role:t('content[6].role'),
                text:t('content[6].text')
            },
            {
                img:"arnaud-houa.jpeg",
                name: "Arnaud Houa",
                role:t('content[1].role'),
                text:t('content[1].text')
            },
           
            {
                img:"testi-worker.png",
                name: "Ange Goba",
                role:t('content[3].role'),
                text:t('content[3].text')
            },
            {
                img:"freddy-beugre.png",
                name: "Freddy Beugre",
                role:t('content[5].role'),
                text:t('content[5].text')
            },
            {
                img:"chef-station.png",
                name: "Village de Lelouhouroukaha",
                role:t('content[4].role'),
                text:t('content[4].text')
            },
            {
                img:"dido.jpeg",
                name: "Dido",
                role:t('content[7].role'),
                text:t('content[7].text')
            },
            
        ]);

        return {t, testimony}
    }
}
</script>

<style lang='scss' scoped>

@import '../../style/main';
@import '../../style/components/slick';

$pic-size: 10rem;
.testi{
    // display: flex;  
    justify-content: space-between;
    width: calc(100% + 40px);
    overflow: hidden;

    &__nav{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & button{
            @include buttonNoStyle;
            padding: 0 1.4rem;
        }

        & span{
            padding: 0 1rem;
        }

        &--svg{
            @include svg(2rem)
        }
        margin: 1rem 0;
    }

    &__item{
        margin-top: calc(#{$pic-size}/2 + 2rem);
        flex: 0 0 33%;
        background-color: $color-white;
        box-shadow: $shadow;
        position: relative;
        border-radius: 5px;

        & img{
            position: absolute;
            top:0;
            left: 50%;

            width: $pic-size;
            height: $pic-size;

            transform: translate(-50%, -50%);
        }


        & h3{
            text-align: center;
            font-size: 1.8rem;
        }

        & h4{
            text-align: center;
            font-weight: 400;
        }
        padding: calc(#{$pic-size}/2 + 2rem) 4rem 4rem 4rem;
    }

    &__content{
        margin-top: 2rem;
        &--svg{
            @include svg(2rem);
        }

        & p{
            text-align: justify;
        }
    }

}

</style>