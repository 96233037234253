<template>
  <div class="page">
    <page-section>
      <heading-card :data="dataHeading"></heading-card>
      <video-link></video-link>  
      <home-deployment></home-deployment>
    </page-section>
    <page-section color="grey">
      <section-action :data="dataHomeProject">
       <div v-html="t('project.text')"></div>
      </section-action>  
    </page-section>
    <page-section>
      <home-sdg></home-sdg>
    </page-section>
    <page-section color="grey">
      <home-solar-impulse></home-solar-impulse>
    </page-section>
    <page-section>
      <home-testimony></home-testimony>
      <picture-line class="u-margin-top-6rem" :data="pictures"></picture-line>
    </page-section>
      
  <!-- <page-section>
    <under-dev></under-dev>
  </page-section> -->
  </div>
  
</template>

<script>
import HeadingCard from '@/components/HeadingCard';
import HomeDeployment from '@/components/home/HomeDeployment';
import HomeSdg from '@/components/home/HomeSdg';
import HomeSolarImpulse from '@/components/home/HomeSolarImpulse';
import HomeTestimony from '@/components/home/HomeTestimony';

import PictureLine from '@/components/PictureLine';

import PageSection from '@/components/PageSection';

import SectionAction from '@/components/section/SectionAction';
import VideoLink from '@/components/VideoLink.vue';

import UnderDev from '@/components/UnderDev.vue';

import {useI18n} from 'vue-i18n';
import { computed } from 'vue';

export default {
  name: 'Home',
  components: {
    HeadingCard,
    HomeSdg,
    HomeSolarImpulse,
    HomeTestimony,
    PageSection,
    PictureLine,
    HomeDeployment,
    SectionAction,
    VideoLink,
    UnderDev
  },
  setup(){

    const {t} = useI18n({
            messages:{
              en: require('@/locales/en/home.json'),
              fr: require('@/locales/fr/home.json'),
              pt: require('@/locales/pt/home.json'),
              sw: require('@/locales/sw/home.json')
            }
        })


     const dataHeading = computed(() => {
       return {
      title:t('title'),
      image:'photo-pompe-main.jpg',
      alt: t('alt'),
      cta:{
          type:"link",
          target: 'Pump',
          icon: 'pumpanddrink',
          main: t('cta.main'),
          text: t('cta.text')
      }
     }
     });

    const dataHomeProject = computed(() => {
      return {
      img: "home-project.jpg",
      cta:{
          type:'link',
          target: 'Project',
          icon: 'ivory',
          main: t('project.cta.main'),
          text: t('project.cta.text'),
          quote: false
      }
    }});

    const pictures = [
             {
                 name:"pic-gallery-1.jpg"
             },
             {
                 name:"pic-gallery-2.jpg"
             },
             {
                 name:"pic-gallery-3.jpg"
             },
             {
                 name:"pic-gallery-4.jpg"
             },
             {
                 name:"pic-gallery-5.jpg"
             },
        ];
    
    return {dataHeading, dataHomeProject, pictures, t}
  }
}
</script>