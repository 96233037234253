<template>
    <div v-if="$store.state.popup.display" class="popup">
        <button id="btn-popup-close" class="popup__close" @click="$store.commit('hidePopup')">
            <Svg className="popup__close--svg" icon="delete"/>
        </button>
        <div class="popup__content">
            <image-popup  v-if="$store.state.popup.type=== 'image'" :data="$store.state.popup.data"></image-popup>
            <div v-else class="video">
                <iframe :src="video" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Svg from './utils/Svg';

import ImagePopup from './ImagePopup';

import {videos} from '@/composables/videos';
export default {
    components: {
        ImagePopup,
        Svg
    },
    setup(){
        const store = useStore();

        const links = videos();

        const video = computed(() => 
            links[store.state.language]
        )

        return {video}
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/main';

.video{
        position: relative;
        margin: 0 auto;
        padding-top: $page-max-width*0.5625; // 16:9
        width: 100%;
        height: 0;

        @include respond(phone){
          padding-top: 56.25%; // 16:9
        }


        & iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

.popup{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(100, 100, 100, 0.05);

    z-index: 10000;
    opacity: 1;
    backface-visibility: hidden;

    transition: all 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;

    @supports (-webkit-backdrop-filter: blur(10px)) or
        (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba($color-black, 0.3);
    }
    &__content {
        position: relative;
        max-width: $page-max-width;
        margin: 3rem;
        width: 100%;
        height: auto;
        background-color: $color-white;
        opacity: 1;
        
        display: flex;
        flex-direction: column;

        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.5);
    }
    &__close {
        @include buttonNoStyle;
        position: absolute;
        top: 2rem;
        right: 2rem;

        color: $color-white;
        transition: all 0.2s;
        opacity: 0.5;

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 1000000;
        
        &:hover {
            opacity: 1;
            transform: scale(1.2);
        }

        &--svg {
            @include svg(2.2rem);
        }
    }
}

</style>