<template>
    <component :is="type" :to="{name:data.target}" class="cta" @click=" data.type==='scroll' ? smoothScroll(data.target) : false">
        <img :src="require(`@/assets/icons/${data.icon}.svg`)" alt="" class="cta__img">
        <div class="cta__text">
            <span>
                {{data.main}}
            </span>
            {{data.text}}
        </div>
    </component>
</template>

<script>
import {smoothScroll} from '@/composables/scroll.js'
export default {
    props:['data'],
    setup(props){
        const type = props.data.type === 'scroll' ? 'button': 'router-link';
        return {
            smoothScroll,
            type
            
        }
    }
}
</script>

<style lang="scss" scoped>

@import '../../style/main';
.cta{
    @include buttonNoStyle;
    text-align: left;
    margin: 3rem 0;
    background-color: $color-white;
    display: flex;
    padding: 1.5rem 2rem;
    border-radius: 8px;
    box-shadow: $shadow;

    text-decoration: none;
    color:$color-primary;
    border: 1px solid currentColor;

    align-items: center;
    transition: all .2s;

     @include respond(phone){
        margin: 2rem 0;
        padding: 1rem 1.6rem;
    }

    &:hover{
        transform: scale(1.04);
    }

    &__img{
        width: 4rem;
        height: auto;
        margin-right: 2rem;
    }

    &__text{
        font-size: 1.6rem;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        line-height: 2rem;
        font-weight: 300;

        & span{
            font-weight: 700;
            
        }
    }

}

</style>