<template>
    <section-title logo="sdg.png" link="https://sdgs.un.org/goals">{{t('title')}}</section-title>
    <div class="sdg">
        <div class="sdg__item sdg__item--6">
            <div class="sdg__icon sdg__icon--6">
                <img src="@/assets/icons/sdg-6.svg" alt="sdg goals 6 svg">
            </div>
            <div class="sdg__content">
                <div class="sdg__content--item">
                    <img src="@/assets/icons/sdg-6-tap.svg" alt="sdg water tap">
                    <h4 class="sdg__content--item-6">
                        {{t('goal6.water.title')}}
                    </h4>
                    <h5>
                        {{t('goal6.water.subtitle')}}
                    </h5>
                </div>
                <div class="sdg__content--item">
                    <img src="@/assets/icons/sdg-6-glass.svg" alt="sdg water glass">
                    <h4 class="sdg__content--item-6">
                        {{t('goal6.pump.title')}}
                    </h4>
                    <h5>
                        {{t('goal6.pump.subtitle')}}
                    </h5>
                </div>
            </div>
        </div>
         <div class="sdg__item sdg__item--7">
            <div class="sdg__icon sdg__icon--7">
                <img src="@/assets/icons/sdg-7.svg" alt="sdg goal 7 svg">
            </div>
            <div class="sdg__content">
                <div class="sdg__content--item">
                    <img src="@/assets/icons/sdg-7-energy-source.svg" alt="sdg energy production">
                    <h4 class="sdg__content--item-7">
                        {{t('goal7.greenhouse.title')}}
                    </h4>
                    <h5>
                        {{t('goal7.greenhouse.subtitle')}}
                    </h5>
                </div>
                <div class="sdg__content--item">
                    <img src="@/assets/icons/sdg-7-energy.svg" alt="sdg battery energy">
                    <h4 class="sdg__content--item-7">
                        {{t('goal7.energy.title')}}
                    </h4>
                    <h5>
                        {{t('goal7.energy.subtitle')}}
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionTitle from '@/components/section/SectionTitle';
import { useI18n } from 'vue-i18n';

export default {
     components: {
        SectionTitle,
    },
    setup(){
        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/sdg.json'),
              fr: require('@/locales/fr/sdg.json'),
              pt: require('@/locales/pt/sdg.json'),
              sw: require('@/locales/sw/sdg.json')
            }
        })

        return{
            t
        }
    }
}
</script>

<style lang='scss' scoped>
@import '../../style/main';

.sdg{
    display: flex;
    flex-direction: column;

    &__item{
        display: flex;
        border: 1px solid currentColor;
        border-radius: 5px;
        background-color: $color-white;
        box-shadow: $shadow;

        @include respond(phone){
            flex-direction: column;
        }
        &--6{
            color: #009fcf;  
        }
        &--7{
            color: #f3b000;  
        }

        &:not(:last-child){
            margin-bottom: 2rem;
        }

    }

    &__icon{
        flex: 0 0 20%;

         &--6{
            background-color: #009fcf;  
            }
        &--7{
            background-color: #f3b000;  
        }
         @include respond(phone){
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
        }

        & img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include respond(phone){
                width: 50%;
            }
        }
    }

    &__content{
        display: flex;
        justify-content: space-between;
        flex: 1;
        padding: 2rem;

        @include respond(phone){
            flex-direction: column;
        }
        &--item{
            flex: 0 0 48%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include respond(phone){
                margin-bottom: 3rem;
            }
            & img{
                width: auto;
                height: 10rem;
                margin-bottom: 1.6rem;
                

            }

            & h4{
                color:$color-white;
                
                padding: .5rem 1.4rem;
                margin-bottom: .7rem;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: .1rem;
                font-weight: 400;
            }

            &-6{
                background-color: #009fcf;
            }
            &-7{
                background-color: #f3b000;
            }

            & h5{
                font-size: 1.4rem;
                font-weight: 400;
                text-align: center;
            }
        }
    }

}

</style>