<template>
  <button class="videolink" @click="popupVideo">
      <Svg icon="youtube" className="videolink__svg"/>
      {{t('link')}}
  </button>
</template>

<script>
import Svg from './utils/Svg';
 import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
export default {
    components:{
        Svg
    },
    setup(){
        const store = useStore();
        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/video.json'),
              fr: require('@/locales/fr/video.json'),
              pt: require('@/locales/pt/video.json'),
              sw: require('@/locales/sw/video.json')
            }
        });
        const popupVideo = () => {
            store.state.popup.display =  true;
            store.state.popup.type = 'video';
        }
        return {
            popupVideo,
            t
        }
    }

}
</script>

<style lang="scss" scoped>
@import '../style/main';

.videolink{
    @include buttonNoStyle;
    border: 1px solid $color-primary;
    display: flex;
    width: 100%;
    border-radius: 3px;
    padding: 1.4rem 4rem;
    font-size: 1.8rem;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: 4rem;
    background-color: $color-white;
    box-shadow: $shadow;

    transition: all .2s;

    &:hover{
        background-color: $color-background-grey
    }
    &__svg{
        @include svg(5rem);
        margin-right: 4rem;
    }
}

</style>