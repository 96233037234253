<template>
    <div class="waiting">
      <h1>
        New website coming soon !
      </h1>
      <h2>
        In the meantime, discover our pump&drink
      </h2>
      <div class="video">
              <iframe src="https://player.vimeo.com/video/514239050" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '../style/main';
    .waiting{
    height: calc(100vh - #{$nav-height});

    display: flex;
    flex-direction: column;
    align-items: center;

    & h1{
        font-size: 4rem;
        text-transform: uppercase;
        letter-spacing: .2rem;
    }

    & h2{
        color:$color-secondary;

        font-weight: 400;
        margin-bottom: 3rem;
    }
    }

    .video{
        position: relative;
        margin: 0 auto;
        padding-top: $page-max-width*0.5625; // 16:9
        width: 100%;
        height: 0;


        & iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }





</style>
