<template>
   <svg :class="`icon-svg ${className ? className : ''}`" :style="size ? `height: ${size}rem; width: ${size}rem;` : ''">
        <use :href="`/img/illustrations.svg#${icon}`" ></use>
    </svg>
</template>

<script>
export default {
    props: [
        'className', 'icon', 'size'
    ],
}
</script>
<style lang="scss">
@import '@/style/main';

.icon-svg{
    display: block;
    fill: currentColor;
    transform: rotate(180deg) scaleX(-1);
}
</style>