export const languages = () => {
  return [
    {
      name: "English",
      locale: "en",
    },
    {
      name: "Français",
      locale: "fr",
    },
    {
      name: "Português",
      locale: "pt",
    },
    {
      name: "Kiswahili",
      locale: "sw",
    },
  ];
};
