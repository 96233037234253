<template>
  <div class="picture">
      <div v-for="(pic, i) in data" :key="`pic-${i}`" class="picture__item">
          <img :src="require(`@/assets/gallery/${pic.name}`)" alt="">
      </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
    props:['data']
}
</script>

<style lang='scss'>

@import '@/style/main';

.picture{
    width: 100%;
    display: flex;
    // height: 12rem;

    @include respond(phone){
        flex-direction: column;
    }

    &__item{
        &:not(:last-child){
            margin-right: 2rem;
            @include respond(phone){
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }

        & img{
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}

</style>