<template>
  <Popup />
  <Header />
  <router-view/>
  <Footer />
  
</template>

<script>
import Header from '@/components/Header'
import Popup from '@/components/Popup'
import Footer from '@/components/Footer'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'; 
import { watch } from 'vue'
import {languages} from '@/composables/languages';

export default {
  components:{
    Header,
    Footer,
    Popup
  },
  setup(){
    const store = useStore();
    const {locale}  = useI18n();
    const langues = languages();
  
    //Define language from change
      watch(store.state, (n, o) => {
        if(n.language) {
          locale.value = n.language;
          store.state.languageName = langues.filter(el => el.locale === store.state.language)[0].name

          const html = document.documentElement // returns the html tag
          html.setAttribute('lang', locale.value)
        }
      })
  }
}
</script>

<style lang="scss">
@import './style/main';
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
}

p {
  text-align: justify;
  margin-bottom: 2rem;
}


#app {
  font-family: Poppins, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-primary;
  background-color: $color-background;
  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
	padding: 0rem; /*Mets une bordure au site*/

  ::selection {
    background-color: $color-primary;
    color: $color-white;
  }

}
</style>
