<template>
    <div class="heading">
        <div class="heading__content">
            <h1>
                {{data.title}}
            </h1>
            <button-cta :data="data.cta"></button-cta>
        </div>
        <div class="heading__image">
            <img :src="require(`@/assets/gallery/${data.image}`)" :alt="data.alt" class="heading__image--img">
        </div>
    </div>
</template>

<script>
import ButtonCta from './utils/ButtonCta';
export default {
    props:['data'],
    components:{
        ButtonCta
    }
}
</script>

<style lang="scss" scoped>

@import '@/style/main';
.heading{
    box-shadow: $shadow;
    display: flex;
    background-color: $color-primary;
    color:$color-white;

    margin-bottom: 4rem;

    @include respond(phone){
        flex-direction: column-reverse;
    }


    &__image{
        flex: 0 0 60%;
        align-self: stretch;
        &--img{
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            
        }
    }


    &__content{
        padding: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

         @include respond(phone){
            padding: 3rem;
            }

        & h1{
            font-size: 5rem;
            line-height: 5.5rem;
            font-weight: 700;
            letter-spacing: .3rem;
            text-transform: uppercase;

            @include respond(phone){
                font-size: 2.4rem;
                line-height: 2.9rem;
                // text-align: center;
            }
        }
    }
}

</style>